<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="content">
            <div class="info-box">
              <p>{{ $t(countrySetup[countryCode].header) }}</p>
              <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
              <p class="title">
                {{ $t('deposit.method.form.header', { method: $t(countrySetup[countryCode].header) }) }}
              </p>
            </div>
            <div class="form_main">
              <el-form label-position="top" :model="zotaForm" ref="zotaForm" status-icon :rules="zotaRules">
                <div class="form-box">
                  <ul class="clearfix">
                    <li>
                      <AccountNumber
                        :supportedCurrenciesList="validCurrencies"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li>
                      <el-form-item :label="setAmtLabel(accountCurrency, zotaForm.amount)" prop="amount">
                        <numeric-input
                          v-model="zotaForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="data" v-if="countryCode !== congoCountryCode">
                      <p>
                        {{
                          $t('deposit.default.rate.rate', {
                            oldCurrency: 'USD',
                            newCurrency: countryCurrency
                          })
                        }}
                        <span>{{ rate }}</span>
                      </p>
                      <p>
                        {{ countryCurrency + ':' }}<span>{{ getRate(rateChange) }}</span>
                      </p>
                    </li>
                    <li>
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="zotaForm.notes" data-testid="imptNotes"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                </div>
                <el-form-item>
                  <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                    {{ $t('common.button.submit') }}
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div class="logo circle" :class="countrySetup[countryCode].logo"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import zotapayMixin from '@/mixins/page/deposit/zotapay';
import { apiQueryExchangeRate, apiZotapay_bankwire_payment } from '@/resource';

export default {
  name: 'AfricaZotapay',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo },
  mixins: [mixin, zotapayMixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.getMaxUSCByRate(this.maxLimit, this.rate)) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      zotaRules: {
        accountNumber: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      validCurrencies: ['USD', 'USC'],
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.reminder.inst12'),
        this.$t('deposit.reminder.inst13')
      ],
      showNote: false
    };
  },
  methods: {
    submitDeposit() {
      return apiZotapay_bankwire_payment(
        {
          mt4Account: this.zotaForm.accountNumber,
          operateAmount: this.zotaForm.amount,
          applicationNotes: this.zotaForm.notes,
          countryCode: this.countryCode,
          depositAmount: this.countryCode !== this.congoCountryCode ? this.getRate(this.rateChange) : 0,
          rate: this.countryCode !== this.congoCountryCode ? this.rate : 0
        },
        this.token
      );
    },
    queryRate() {
      if (this.countryCode !== this.congoCountryCode) {
        apiQueryExchangeRate()
          .then(resp => {
            if (resp.data.code == 0) this.rate = resp.data.data;
          })
          .catch(err => {
            this.errorMessage(
              this.$t('deposit.default.rate.result.rateError', {
                oldCurrency: 'USD',
                newCurrency: this.countryCurrency
              })
            );
          });
      }
    }
  },
  mounted() {
    this.queryRate();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
